<template>
  <window-default ref="window" :resolve="find" :mock="mock" :props="props" v-slot="{data}" @loaded="(obj) => model = obj" @save="save">
    <window-body class="wrapper-lg">
      <e-row mr>
        <e-col size="1">
          <erp-s-field
              label="ID:"
            >
            <erp-input v-model="model.id" size="2" readonly />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Descricao:"
          >
            <erp-input v-model="model.name" autofocus size="2" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Ativo:"
          >
            <erp-checkbox v-model="model.active" />
          </erp-s-field>
        </e-col>
      </e-row>
    </window-body>
  </window-default>
</template>

<script>
import {WindowBody, ErpSField, ErpInput, ErpCheckbox} from 'uloc-vue-plugin-erp'
import WindowDefault from '../../../../../reuse/window/Default'
import {
  findTiposEndereco,
  newTipoEndereco as _new,
  updateTipoEndereco as update,
} from '../../../../../domain/cadastro/services/tiposEndereco'
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
import save from '../../../helpers/save'

let mock = {
  id: null,
  name: null,
  active: true
}

export default {
  name: 'Window',
  components: {ECol, ERow, WindowDefault, WindowBody, ErpSField, ErpInput, ErpCheckbox},
  props: ['props'],
  data () {
    return {
      model: JSON.parse(JSON.stringify(mock))
    }
  },
  mounted () {
  },
  computed: {
    find () {
      return findTiposEndereco
    },
    mock () {
      return mock
    }
  },
  methods: {
    update: update,
    new: _new,
    save: save
  }
}
</script>

<style scoped>

</style>
